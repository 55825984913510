





































import { Vue, Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon }      from '@fortawesome/vue-fontawesome'
import { isObject } from '@/utils/helpers'
/**
 * Composant relatif à n bloc de commentaire pour l'édition des candidats
 */
@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class ErrorDisplay extends Vue {
    @Prop() error?: any[]
    isObject = isObject
}
